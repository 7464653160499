import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Moment from 'react-moment';
import Assignment from '../../_models/Assignment'

class AssignmentsListItemBody extends Component {
  render () {
    const category = this.props.assignment.categories.length > 0 ? this.props.assignment.categories[0] : null
  
    return (
      <div className="assignment-item-body row no-gutters h-100 mr-2 p-4 bg-white">
        <div className='col-9'>
          <div className='assignment-item__title'>{this.props.assignment.title}</div>
          <div className='assignment-item__question-category'>{category && category.title}</div>
          <div className='assignment-item__questions-status'>
            <span className='assignment-item__questions-status__complete-questions'>{this.props.answeredQuestionsTotalCount}</span>
            /{this.props.questionsTotalCount} {'Questions by'}
            <span className='assignment-item__questions-status__user'> {this.props.assignment.user.username}</span>
          </div>
        </div>
        <div className='col-3'>
          <div className='assignment-item__deadline-label'>{'Deadline'}</div>
          <div className='assignment-item__deadline-content'><Moment utc fromNow tz={this.props.tz || ''}>{this.props.assignment.dueDate}</Moment></div>
        </div>
      </div>
    )
  }
}

AssignmentsListItemBody.propTypes = {
  assignment: PropTypes.instanceOf(Assignment).isRequired,
  questionsTotalCount: PropTypes.number,
  answeredQuestionsTotalCount: PropTypes.number,
  tz: PropTypes.string,
}

export default AssignmentsListItemBody
